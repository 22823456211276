import { ResourceStatus } from "@prisma/client"
import {
  FaGlobe,
  FaHammer,
  FaMapPin,
  FaMicrophone,
  FaMobileAlt,
  FaNewspaper,
  FaPaperclip,
  FaQuestion,
  FaSchool,
  FaUser,
  FaUsers,
  FaVideo,
} from "react-icons/fa"
import { FiBookOpen } from "react-icons/fi"
import { ISingleSelectItem } from "src/core/interfaces/interfaces"

export const RESOURCES_PAGE_LIMIT: number = 6

export enum TagType {
  SAVED = "saved",
  LIKED = "liked",
  HIDDEN = "hidden",
}

export const TAG_TYPES: ISingleSelectItem[] = [
  {
    key: TagType.SAVED,
    value: "Saved For Later",
    action: "Save",
  },
  {
    key: TagType.LIKED,
    value: "Liked",
    action: "Like",
  },
  {
    key: TagType.HIDDEN,
    value: "Hidden",
    action: "Hide",
  },
  {
    key: undefined,
    value: "New",
  },
]

export enum ResourceType {
  ARTICLE = "article",
  VIDEO = "video",
  BOOK = "book",
  PLACE = "place",
  PODCAST = "podcast",
  APP = "app",
  COURSE = "course",
  TOOL = "tool",
  EXPERT = "expert",
  OTHER = "other",
  FILE = "file",
  FORUM = "forum",
}

export const RESOURCE_TYPES: ISingleSelectItem[] = [
  {
    key: undefined,
    value: "All",
    icon: FaGlobe,
  },
  {
    key: ResourceType.ARTICLE,
    value: "Article",
    icon: FaNewspaper,
  },
  {
    key: ResourceType.VIDEO,
    value: "Video",
    icon: FaVideo,
  },
  {
    key: ResourceType.BOOK,
    value: "Book",
    icon: FiBookOpen,
  },
  {
    key: ResourceType.PODCAST,
    value: "Podcast",
    icon: FaMicrophone,
  },
  {
    key: ResourceType.PLACE,
    value: "Place",
    icon: FaMapPin,
  },
  {
    key: ResourceType.APP,
    value: "App",
    icon: FaMobileAlt,
  },
  {
    key: ResourceType.COURSE,
    value: "Course",
    icon: FaSchool,
  },
  {
    key: ResourceType.TOOL,
    value: "Tool",
    icon: FaHammer,
  },
  {
    key: ResourceType.EXPERT,
    value: "Expert",
    icon: FaUser,
  },
  {
    key: ResourceType.FORUM,
    value: "Forum",
    icon: FaUsers,
  },
  {
    key: ResourceType.OTHER,
    value: "Other",
    icon: FaQuestion,
  },
  {
    key: ResourceType.FILE,
    value: "File",
    icon: FaPaperclip,
  },
]

export enum SortType {
  BEST = "best",
  NEWEST = "newest",
}

export const SORT_TYPES: ISingleSelectItem[] = [
  {
    key: SortType.BEST,
    value: "Best",
  },
  {
    key: SortType.NEWEST,
    value: "Newest",
  },
]

export const RESOURCE_STATUSES: ISingleSelectItem[] = [
  {
    key: ResourceStatus.APPROVED,
    value: "Approved",
  },
  {
    key: ResourceStatus.PENDING,
    value: "Pending",
  },
  {
    key: ResourceStatus.REJECTED,
    value: "Rejected",
  },
]

export const CONTENT_TYPE_WHITELIST = {
  "image/jpeg": "jpg",
  "image/png": "png",
  "image/gif": "gif",
  "image/svg+xml": "svg",
  "application/pdf": "pdf",
  "application/epub+zip": "epub",
  "application/x-mobipocket-ebook": "mobi",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
  "text/plain": "txt",
  "audio/mpeg": "mp3",
  "audio/wav": "wav",
  "video/mp4": "mp4",
  "text/html": "html",
}
