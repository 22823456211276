import { useSession } from "@blitzjs/auth"
import { Disclosure } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"
import { useRouter } from "next/router"
import { FaInfinity } from "react-icons/fa"
import { AuthProvider } from "src/auth/auth.context"
import { classNames, parseBoolean } from "utils"
import { isMobileBrowser } from "./Tree/utilities"
import { UserMenu } from "./UserMenu"

interface INavOption {
  name: string
  href: string
  current: boolean
  disabled?: boolean
}

interface Props {
  options: INavOption[]
}

const navOptions: INavOption[] = [
  { name: "Objectives", href: "/objectives", current: true },
  {
    name: "Resources",
    href: "/resources",
    current: false,
    disabled: false,
  },
  { name: "Users", href: "/users", current: false },
  ...(isMobileBrowser() ? [] : [{ name: "Bookmarklet", href: "/bookmarklet", current: false }]),
  {
    name: "Feedback?",
    href: "/feedback",
    current: false,
    disabled: !!!process.env.CANNY_BOARD_TOKEN,
  },
]

export const NavOptions = ({ options }: Props) => {
  const router = useRouter()

  return (
    <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
      {options
        .filter((option) => !option.disabled)
        .map(({ current, href, name }: INavOption, idx: number) => (
          <a
            key={name}
            href={href}
            className={classNames(
              router.pathname.indexOf(href) > -1 || (router.pathname === "/" && idx === 0)
                ? "border-red-500 text-gray-800"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
              "inline-flex items-center px-1 pt-1 text-md font-medium  border-b-2 "
            )}
            aria-current={current ? "page" : undefined}
          >
            {name}
          </a>
        ))}
    </div>
  )
}

export const MobileNavOptions = ({ options }: Props) => {
  const router = useRouter()

  return (
    <div className="pt-2 pb-4 space-y-1">
      {options.map(({ current, href, name }: INavOption) => (
        <a
          key={name}
          href={href}
          className={classNames(
            router.pathname.indexOf(href) > -1
              ? "bg-red-50 border-red-500 text-red-700"
              : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700",
            "block py-2 pl-3 pr-4 text-base font-medium  border-l-4"
          )}
          aria-current={current ? "page" : undefined}
        >
          {name}
        </a>
      ))}
    </div>
  )
}

export const Nav = () => {
  const session = useSession({ suspense: false })

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-5xl px-2 mx-auto">
            <div className="relative flex justify-between h-16">
              {!!session?.userId && (
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block w-6 h-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              )}

              <div className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
                <a title="logo" className="flex items-center flex-shrink-0" href="/">
                  <FaInfinity
                    className="block w-8 h-8 text-red-700 fill-current"
                    aria-hidden="true"
                  />
                </a>
                {!!session?.userId && <NavOptions options={navOptions} />}
              </div>

              {!!session?.userId ? (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* <button className="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="w-6 h-6" aria-hidden="true" />
                  </button> */}

                  {/* Profile dropdown */}
                  <AuthProvider>
                    <UserMenu />
                  </AuthProvider>
                </div>
              ) : (
                <div className="m-2">
                  <a
                    className="h-full inline-flex items-center ml-2 px-2.5 py-1.5 text-md font-medium hover:text-white text-gray-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    href="/login"
                  >
                    Sign in
                  </a>
                  {parseBoolean(process.env.SIGNUP_ENABLED) && (
                    <a
                      href="/signup"
                      className=" h-full inline-flex items-center ml-2 px-2.5 py-1.5 text-md font-medium shadow-sm text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Create an account
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <MobileNavOptions options={navOptions} />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
