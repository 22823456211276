import Image from "next/image"
import { ReactElement, useState } from "react"
import { classNames } from "utils"

interface Props {
  src: string
  className?: string
  placeholder?: ReactElement
}

const Img = ({ src, className, placeholder }: Props) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <>
      <Image
        loading="eager"
        src={
          src?.length && src.indexOf("http") >= 0
            ? `/api/imgproxy?url=${encodeURIComponent(src)}`
            : src
        }
        alt=""
        fill={true}
        className={classNames("relative", className)}
        onLoad={() => {
          setLoaded(true)
        }}
        sizes="(max-width: 768px) 100vw, 768px"
      />

      {!loaded && placeholder}
    </>
  )
  // return <Image src={src} alt="" layout="fill" className={classNames("relative", className)} />
}

export default Img
