import { Menu, Transition } from "@headlessui/react"
import React, { Fragment } from "react"
import { useAuthContext } from "src/auth/auth.context"
import { useCurrentUser } from "src/core/hooks/useCurrentUser"
import { classNames } from "utils"
import Avatar from "./Avatar"

export const UserMenu = () => {
  const user = useCurrentUser()

  const { logout } = useAuthContext()

  const userNavigation = [
    { name: "Account", href: "/account" },
    { name: "Sign out", href: "#", action: logout },
  ]

  return (
    <Menu as="div" className="relative ml-3">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
              <span className="sr-only">Open user menu</span>
              <Avatar
                name={user?.profile?.name}
                src={user?.profile?.photoUrl}
                className="w-12 h-12"
              />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 z-50 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <a
                      href={item.href}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                      onClick={item.action}
                    >
                      {item.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export const MobileUserMenu = () => {
  const { logout } = useAuthContext()

  const userNavigation = [
    { name: "Account", href: "/account" },
    { name: "Sign out", href: "#", action: logout },
  ]
  return (
    <div className="mt-3 space-y-1">
      {userNavigation.map((item) => (
        <a
          key={item.name}
          href={item.href}
          className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
        >
          {item.name}
        </a>
      ))}
    </div>
  )
}
