import { classNames, getInitials } from "utils"
import Img from "./Img"

interface Props {
  name?: string
  initials?: string
  src?: string | null
  className?: string
}

const Initials = ({ initials }: Props) => {
  return (
    <span className="inline-flex items-center justify-center w-full h-full bg-gray-500 rounded-full">
      <span className="text-xs font-medium leading-none text-white">{initials}</span>
    </span>
  )
}

const Avatar = ({ name, initials, src, className }: Props) => {
  return (
    <div className={classNames("relative", className)}>
      {src ? (
        <>
          {/* <Image src={src} alt="" layout="fill" className="rounded-full" /> */}
          <Img
            src={src}
            className="rounded-full"
            placeholder={<Initials initials={initials || (name && getInitials(name)) || "N/A"} />}
          />
        </>
      ) : (
        <Initials initials={initials || (name && getInitials(name)) || "N/A"} />
      )}
    </div>
  )
}

export default Avatar
