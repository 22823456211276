import { useMutation } from "@blitzjs/rpc"
import constate from "constate"
import { useRouter } from "next/router"
import { useState } from "react"
import logout from "./mutations/logout"

const useAuth = () => {
  const router = useRouter()

  const [logoutLoading, setLogoutLoading] = useState(false)

  const [logoutMutation] = useMutation(logout)

  const logoutAction = async () => {
    if (logoutLoading) return

    setLogoutLoading(true)

    try {
      await logoutMutation()
      await router.push("/")
    } finally {
      setLogoutLoading(false)
    }
  }

  return {
    logout: logoutAction,
  }
}

export const [AuthProvider, useAuthContext] = constate(useAuth)
